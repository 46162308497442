// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: "AIzaSyAi3HR79hWqAvCnTgEeUMcDEAh1lkskYoo",
    authDomain: "comparamotors-fe8bf.firebaseapp.com",
    databaseURL: "https://comparamotors-fe8bf.firebaseio.com",
    projectId: "comparamotors-fe8bf",
    storageBucket: "comparamotors-fe8bf.appspot.com",
    messagingSenderId: "390981328083",
    appId: "1:390981328083:web:f3f7a22e9a51ca4df48826",
    measurementId: "G-YQG6B1K196"
  },
  production: true,
  baseUrl: "https://us-central1-comparamotors-fe8bf.cloudfunctions.net/apiUsers/api",
  debug: true,
  limit: 20,
  tokenMessaging: "BNBYS3vBXVPl_50fDA3jDCg2zGJaw52YnIsaFxbG5sq_kV45dtsg1V0QL4MrCyi4gE3NSpFc5Ag1dpdGztlIboA",
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import { Component, OnInit } from '@angular/core';
import { FCMService } from './services/fcm.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title: string = 'Comparamotors';

  constructor(
    private fcm: FCMService,
    private meta: Meta
  ) {

  }


  ngOnInit(): void {
    this.fcm.message$.subscribe({
      next: message => {
        console.log(message);
      },
      error: err => {
        console.error(err);
      }
    })
    this.fcm.token$?.subscribe({
      next: message => {
        console.log(message);
        this.fcm.registerToken(message);
      },
      error: err => {
        console.error(err);
      }
    })
    this.meta.addTag({ name: 'description', content: 'Últimas noticias sobre autos y motos. Encuentra información sobre nuevos modelos, lanzamientos y tendencias en el mundo automotriz y motociclístico.' });

    // Metaetiqueta de palabras clave
    this.meta.addTag({ name: 'keywords', content: 'autos, motos, noticias de autos, noticias de motos, nuevos modelos, lanzamientos, tendencias automotrices, tendencias motociclísticas' });

    // Metaetiqueta de autor
    this.meta.addTag({ name: 'author', content: 'Comparamotors' });

    // Metaetiqueta de título
    this.meta.addTag({ property: 'og:title', content: 'Noticias de Autos y Motos' });

    // Metaetiqueta de imagen para redes sociales
    this.meta.addTag({ property: 'og:image', content: 'https://comparamotors.com/assets/images/XMLID_15_.png' });

    // Metaetiqueta de URL para redes sociales
    this.meta.addTag({ property: 'og:url', content: 'https://comparamotors.com/' });

    // Metaetiqueta de tipo de contenido para redes sociales
    this.meta.addTag({ property: 'og:type', content: 'website' });
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Messaging, getMessaging, getToken, onMessage } from '@angular/fire/messaging';
import { environment } from '@environments/environment';
import { Observable, from } from 'rxjs';
import { share, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FCMService {


  token$!: Observable<any>;
  message$!: Observable<any>;

  private apiUrl = environment.baseUrl

  constructor(
    @Optional() messaging: Messaging,
    private http: HttpClient
  ) {
    if ('Notification' in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') console.log('Permission granted');
        else if (permission === 'denied') console.log('Permission denied');
      }).catch(err => {
        console.error(err);
  
      });
    }
    // Request permission to receive notifications
    

    if (messaging) {
      this.token$ = from(
        navigator.serviceWorker
          .register('firebase-messaging-sw.js', { type: 'module', scope: '__' })
          .then((serviceWorkerRegistration) =>
            getToken(messaging, {
              serviceWorkerRegistration,
              vapidKey: environment.tokenMessaging,
            })
          )
      ).pipe(
        tap((token) => console.log('FCM', { token })),
        share()
      );
      this.message$ = new Observable((sub) => onMessage(messaging, (it) => sub.next(it))).pipe(
        tap((it) => console.log('FCM', it))
      );

      this.message$.subscribe((x) => console.log('new foreground message', x));
    }
  }

  registerToken(token: string) {
    return this.http.post(`${this.apiUrl}/fcm`, { tokenFCM: token }).subscribe({
      next: response => {
        console.log(response);
      },
      error: err => {
        console.error(err);

      }
    });
  }

}
